import { useState } from "react";
import PropTypes from "prop-types";
import clsx from "classnames";
import { Col, Container, Row } from "react-bootstrap";
import SourceFlowImage from "@sourceflow-uk/sourceflowimage";
import classes from "./styles.module.scss";
import ArrowRight from "@/assets/ArrowRight.svg";
import { ensureTrailingSlash } from '@/functions/ensureTrailingSlash';

export default function TileGroups({ className, groups }) {
  const [visibleCount, setVisibleCount] = useState(4);
  if (!groups || groups.length === 0) {
    return null;
  }

  return (
    <aside className={clsx(className, classes.TileGroups)}>
      <Container>
        <Row>
          {groups.map((group, key) => (
            <Col key={key} xs={12} lg={6} className="mb-4">
              <section className={classes.TileGroups__group}>
                <h3>{group.title}</h3>
                <Row>
                  {group.items.slice(0, visibleCount).map((i, k) => (
                    <Col key={k} xs={12} sm={6} className="mb-4">
                      <a href={ensureTrailingSlash(i.url)} className={classes.TileGroups__card}>
                        {i?.tag && (
                          <i className="py-1 px-2 gap-1">
                            <span>{i?.tag_icon && <img src={i.tag_icon} alt="" />}</span>{" "}
                            <span dangerouslySetInnerHTML={{ __html: i.tag }} />
                          </i>
                        )}
                        <SourceFlowImage src={i.image} size="282x188" alt={i.label} />
                        <footer>
                          <a href={ensureTrailingSlash(i.url)}>
                            {i.label} <ArrowRight />
                          </a>
                        </footer>
                      </a>
                    </Col>
                  ))}
                </Row>
                {visibleCount === 4 && (
                  <footer className="d-flex justify-content-center">
                    {group.footer_links.map((l, k) => (
                      <a
                        key={k}
                        href={ensureTrailingSlash(l.url)}
                        onClick={(e) => {
                          if (l.url === "#") {
                            e.preventDefault();
                            setVisibleCount(6);
                          }
                        }}
                      >
                        {l.label}
                      </a>
                    ))}
                  </footer>
                )}
              </section>
            </Col>
          ))}
        </Row>
      </Container>
    </aside>
  );
}

TileGroups.defaultProps = {
  className: "py-5",
  groups: null,
};

TileGroups.propTypes = {
  className: PropTypes.string,
};
